<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 500px; overflow-y: hidden;"
      v-model="valueHtml"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="handleCreated"
    />
  </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  components: { Editor, Toolbar },
  mounted(){

  },
  onBeforeUnmount(){
      const editor = this.editorRef
      if (editor == null) return
      editor.destroy()
  },
  data(){
    return{
      editorRef:null,
      valueHtml:'123',
      mode: 'default', // 或 'simple'
      toolbarConfig:{},
      editorConfig:{
        placeholder: '请输入内容...' 
      }
    }
  },
  methods:{
    handleCreated(editor){
      this.editorRef = editor // 记录 editor 实例，重要！
      console.log(editor)
    }
  }

}
</script>
<style lang="scss" scoped>

</style>
