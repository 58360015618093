<template>
    <div class="pageBox">
        <div class="bannerBox"></div>
        <div class="topBox">
            <div class="title">
                <div> —— </div>
                <div class="titleCenter"> 文保资料 </div>
                <div> —— </div>
            </div>
            <div class="typeContain PosiContain">
                <div class="TCpB-left">
                    <div class="TCp-title">
                        <div class="TCp-left">法律法规</div>
                        <div class="TCp-right"></div>
                    </div>
                    <div class="TCp-bottom">
                        <div class="TCp-Bottom-left">
                            <el-carousel height="240px">
                                <el-carousel-item v-for="(item, indexs) in limitBans" :key="indexs">
                                    <img :src="item" style="width: 100%;height: 100%;object-fit: cover;"/>
                                </el-carousel-item>
                            </el-carousel>
                            <div class="TCPBL-title" @click="goEntery(Ruleslist[0]?Ruleslist[0].id:'')">
                                {{ Ruleslist[0]?Ruleslist[0].title:'' }}
                            </div>
                            <div class="TCPBL-Msg">
                                {{ Ruleslist[0]?Ruleslist[0].summary:'' }}
                            </div>
                        </div>
                        <div class="TCp-Bottom-right">
                            <div class="TCPBR-Name" :style="`display: ${index==0?'none':'flex'}`" v-for="(item, index) in Ruleslist" :key="index" @click="goEntery(item.id)">
                                ■ {{ item.title }}
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="TCpB-right">
                    <div class="TCp-title">
                        <div class="TCp-left">标准规范</div>
                        <div class="TCp-right">
                            
                        </div>
                    </div>
                    <div class="TCPBR-Name" @click="goEntery(item.id)" v-for="(item, index) in standardList" :key="index">
                        {{ item.title }}
                    </div> 
                </div>
            </div>
        </div>
        <div class="topBox">
            <div class="title">
                <div> —— </div>
                <div class="titleCenter"> 专家知识库</div>
                <div> —— </div>
            </div>
            <div class="selectBoxCSS">
                <div class="selectBox">
                    <img @click="changeType(56)" src="../../assets/loginout/se-01.png"/>
                    文物保护
                </div>
                <div class="selectBox">
                    <img @click="changeType(57)" src="../../assets/loginout/se-02.png"/>
                    考古勘探
                </div>
                <div class="selectBox">
                    <img @click="changeType(58)" src="../../assets/loginout/se-03.png"/>
                    博物馆
                </div>
            </div>
            <div class="lineBox">
                <div :class="typeFlag==56?'lineItem lineItemActive':'lineItem'">
                    <div :class="typeFlag==56?'arrow-up arrow-upActive':'arrow-up'"></div>
                </div>
                <div :class="typeFlag==57?'lineItem lineItemActive':'lineItem'">
                    <div :class="typeFlag==57?'arrow-up arrow-upActive':'arrow-up'"></div>
                </div>
                <div :class="typeFlag==58?'lineItem lineItemActive':'lineItem'">
                    <div :class="typeFlag==58?'arrow-up arrow-upActive':'arrow-up'"></div>
                </div>
            </div>
            <div class="boxContain">
                <div class="boxItem" @click="goEntery(item.id)" v-for="(item, index) in expertList" :key="index">
                    <div class="boxItem-left">
                        <img :src="item.cover"/>
                    </div>
                    <div class="boxItem-right">
                        <div class="BR-title">{{item.title}}</div>
                        <div class="BR-scrouce">来源：{{ item.sourcename }}</div>
                        <div class="BR-text">
                            {{ item.summary }}
                        </div>
                    </div>
                </div>
            </div>  
        </div>

    </div>
</template>
<script>
import { OH_byType } from '@/api/openHome.js'
export default{
    data(){
        return{
            limitBans:[
                require('../../assets/loginout/limit-01.jpg'),
                require('../../assets/loginout/limit-02.jpg'),
                require('../../assets/loginout/limit-03.jpg')
            ],
            typeFlag:56,
            expertList:[],
            Ruleslist:[],
            standardList:[]
        }
    },
    mounted(){
        this.GetohType()
        this.changeType(this.typeFlag)
    },
    methods:{
        GetohType(){
            OH_byType({page:1,size:8,types:54}).then(r=>{ //法律法规
                this.Ruleslist = r.data.list  
            })
            OH_byType({page:1,size:7,types:55}).then(r=>{ //标准规范
               this.standardList =  r.data.list       
            })
        },
        changeType(type){
            this.typeFlag = type
            OH_byType({page:1,size:4,types:this.typeFlag}).then(r=>{ //专家库
               this.expertList =  r.data.list       
            })
        },
        goEntery(id){
            if(id){
                this.$router.push({
                    query:{'id':id},
                    path:'/openDetilsFiles'
                })
            }
            
        },
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width:100%;
    padding:0px;
    background-color:white;
}
.bannerBox{
    width:100%;
    height:450px;
    background-image: url('../../assets/loginout/files-banner.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.topBox{
    width:1400px;
    margin: 0 auto;
    .title{
        display:flex;
        justify-content:center;
        align-items:center;
        height:100px;
        margin-top: 80px;
        color:rgb(200, 195, 195);
        .titleCenter{
            margin:0px 20px;
            font-size:20px;
            color:#333;
            font-weight: 600;
        }
    }
    .typeContain{
        width: 100%;
        margin-top: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }
}
.PosiContain{
    .TCpB-left{
        width: calc(65% - 50px);
        .TCp-bottom{
            width: 100%;
            display: flex;
            justify-content: space-between;
           .TCp-Bottom-left{
                width:340px;
                border-bottom: 1px solid rgb(218, 218, 218);
                .TCPBL-title{
                    cursor: pointer;
                    font-weight: 600;
                    margin:20px 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .TCPBL-Msg{
                    font-size: 14px;
                    color: #666;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    text-align: justify;
                    -webkit-line-clamp: 2;
                }
           }
           .TCp-Bottom-right{
                width: calc(100% - 360px);
                .TCPBR-Name{
                    height: 50px;
                    display: flex;
                    cursor: pointer;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid rgb(218, 218, 218);
                    box-sizing: border-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
           } 
        }
    }
    .TCpB-right{
        width: 35%;
        .TCPBR-Name{
            height: 50px;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgb(218, 218, 218);
            box-sizing: border-box;
        }
    }
    .TCp-title{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .TCp-left{
            width: 80px;
            height: 100%;
            display:flex;
            justify-content:flex-start;
            align-items: center;
            border-bottom: 5px solid blue;
            box-sizing: border-box;
            font-weight: 600;
        }
        .TCp-right{
            width: calc(100% - 80px);
            height: 100%;
            font-size: 14px;
            cursor: pointer;
            display:flex;
            justify-content:flex-end;
            align-items: center;
            border-bottom: 1px solid rgb(218, 218, 218);
            box-sizing: border-box;
            color: #666;
        }
    } 
}
.selectBoxCSS{
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.selectBox{
    width: 200px;
    height: 230px;
    text-align: center;
    font-weight: 600;
    img{
        width: 200px;
        height: 200px;
        object-fit:contain;
        cursor: pointer;
    }
}
.lineBox{
    width: 100%;
    height:2px;
    background-color: rgb(218, 218, 218);
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .lineItem{
        width: 33%;
        height: 100%;
        background-color: transparent;
        position: relative;
        .arrow-up{
            position: absolute;
            top:0;
            left: calc(50% - 3px);
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 5px solid transparent;
        }
        .arrow-upActive{
            border-top: 5px solid blue;
        }
    }
    .lineItemActive{
        background-color:blue;
    }
    
}
.boxContain{
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.boxItem{
    width: calc(50% - 10px);
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .boxItem-left{
        width: 230px;
        
        img{
            width: 100%;
            height: 150px;
            object-fit: cover;
        }
    }
    .boxItem-right{
        width: calc(100% - 260px);
        .BR-title{
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .BR-scrouce{
            font-size: 14px;
            color: #666;
            line-height: 40px;
            border-bottom: 1px solid rgb(204, 204, 204);
            margin-bottom: 20px;
        }
        .BR-text{
            font-size: 14px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-align: justify;
            -webkit-line-clamp: 3;
        }
        
    }
}

</style>
