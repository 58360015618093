<template>
  <div id="map"></div>
</template>

<script>
import L from "leaflet"
import "leaflet/dist/leaflet.css"
export default {
  name: 'Home',
  components: {
  },
  mounted(){
      var tiles_path = 'http://huimin-media.microwise-system.com/big_picture_tiles/95老君像' + "/{z}/map_tile_{x}_{y}.jpg";
      var image_width = '12563'
      var image_height = '12874'
      const map = L.map('map',{
        minZoom: 16,
        maxZoom: 20,
        continuousWorld: true,
        reuseTiles: true,
        noWrap: true,
        zoom: 16,
        crs: L.CRS.Simple
      }).setView([0,0],16)

      // 删除 leaflet 标识
		  map.attributionControl.setPrefix("");

      var southWest = map.unproject([0, image_height], map.getMaxZoom());
      var northEast = map.unproject([image_width, 0], map.getMaxZoom());
      map.setMaxBounds(new L.LatLngBounds(southWest, northEast))
      L.tileLayer(tiles_path, {
        name: 'image_tile',
        type: 'xyz',
        continuousWorld: true,
        noWrap: true,
        maxZoom: 20,
        bounds: new L.LatLngBounds(southWest, northEast),
      }).addTo(map)
  },
  methods:{
   

  }
}
</script>
<style scoped lang='scss'>
#map {
  width: 1140px;
  height: 500px;
  z-index: 999999;
  background-color: black;
}
</style>
